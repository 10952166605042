import React, { useState } from "react";

// import { Button } from '../ButtonElements'
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  ImgWrap,
  LoginBox,
  LoginHeading,
  LoginPara,
  InputBox,
  LoginButton,
  LoginBtnWrapper,
  LogoMenzil,
} from "./LoginElements";
import { Formik, Field, Form } from "formik";
import InputLogin from "../InputLogin";
import { withRouter } from "react-router-dom";
import Overlay from "../Overlay";
import axios from "../../axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { makeStyles } from "@material-ui/core/styles";
// import savypackLogoWhite from "../../images/savypackLogoWhite.png";
import logo from "../../images/sarox_logo.svg";
import progrowth from "../../images/blackLogo.png";
import { loginValidator } from "../../utils/validators";

import "./locationdropdown.css";
import { RiLockPasswordLine, RiUserLine } from "react-icons/ri";
// import { FaRegUser } from 'react-icons/fa';
const useStyles = makeStyles((theme) => ({
  formStyle: {
    width: "100%",
    padding: "2rem",
    // height: "80vh",
    overflow: "scroll",
  },
  "@media (max-width: 780px)": {
    formStyle: {
      padding: "1.8rem",
    },
    formStyleOnly: {
      padding: "1.8rem",
    },
  },
  "@media (max-width: 480px)": {
    formStyle: {
      padding: "1.3rem",
    },
    formStyleOnly: {
      padding: "1.3rem",
    },
  },

  formStyleOnly: {
    width: "100%",
    padding: "2rem",
    // height: "80vh",
    // overflow: "scroll",
  },
}));

const InfoSection = ({
  lightBg,
  imgStart,
  img,
  pageHeading,
  pagePara,
  form,
  history,
  setUsers,
  userData,
  defaultState,
  setDefaultState,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [OTPSend, setOtpSend] = useState(false);
  const [otpPhone, setOtpPhone] = useState("");
  const [isForgetiing, setIsForgetting] = useState(false);
  const [modalData, setModalData] = useState({
    isOpen: false,
    header: "success_message",
    message: "Your add will post Shortly",
  });

  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async (values) => {
    setIsLoading(true);
    var url = "/adminLogin";
    var formvalues = {
      email: values.email,
      password: values.password,
    };

    try {
      const { data } = await axios.post(url, formvalues);
      console.log(data)
      if (data) {
        localStorage.setItem("token", data.session.access_token);
        history.push("/coach-management");
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(`Wrong Email or Password`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      <InfoContainer lightBg={lightBg}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column2>
              <ImgWrap>
                <div className="d-flex justify-content-center align-items-center">
                  {/* <img
                    style={{ width: "10rem", marginRight: "10px" }}
                    src={logo}
                    alt=""
                  /> */}
                  {/* <span style={{fontSize:"30px"}}>SAROX</span> */}
                  <img
                    style={{ width: "25rem", paddingTop: "10px" }}
                    src={progrowth}
                    alt="progrowth"
                  ></img>
                </div>
              </ImgWrap>
            </Column2>
            <Column1>
              <TextWrapper
                contentAlign={
                  defaultState.form === "pendingApproval" ? true : false
                }
              >
                <LoginBox>
                  <LogoMenzil>
                    <img
                      style={{ width: "5rem" }}
                      src={logo}
                      alt="mensil logo"
                    ></img>
                  </LogoMenzil>

                  <LoginHeading>Welcome Back !</LoginHeading>
                  <LoginPara style={{ color: "#dfa02f", textAlign: "center" }}>
                    Sign into the AdminPanel
                  </LoginPara>
                </LoginBox>
                <InputBox>
                  <Formik
                    enableReinitialize
                    initialValues={loginValues}
                    validate={loginValidator}
                    autocomplete="off"
                    validateOnChange
                    onSubmit={(values) => handleLogin(values, false)}
                  >
                    {(formikBag) => {
                      return (
                        <Form className={classes.formStyleOnly}>
                          <Field name="email">
                            {({ field }) => (
                              <div className="py-2">
                                <InputLogin
                                  {...field}
                                  type="email"
                                  autocomplete="off"
                                  // icon={nameHolder}
                                  icon={
                                    <RiUserLine style={{ fontSize: "24px" }} />
                                  }
                                  //   value={formikBag.values.password}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "email",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.email &&
                                    formikBag.errors.email
                                      ? formikBag.errors.email
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Email"
                                />
                              </div>
                            )}
                          </Field>
                          <Field name="password">
                            {({ field }) => (
                              <div className="py-2">
                                <InputLogin
                                  {...field}
                                  type="password"
                                  autocomplete="off"
                                  // icon={PassIcon}
                                  icon={
                                    <RiLockPasswordLine
                                      style={{ fontSize: "24px" }}
                                    />
                                  }
                                  //   value={formikBag.values.password}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "password",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.password &&
                                    formikBag.errors.password
                                      ? formikBag.errors.password
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Password"
                                />
                              </div>
                            )}
                          </Field>

                          <LoginBtnWrapper>
                            <LoginButton type="submit">Log In</LoginButton>
                          </LoginBtnWrapper>
                          <p
                            className="text-center"
                            style={{
                              padding: "0.3rem",
                              marginBottom: "1.5rem",
                              color: "#74788d",
                            }}
                          >
                            © 2024 Pro Growth Leadership & Business Coaching
                          </p>
                        </Form>
                      );
                    }}
                  </Formik>
                </InputBox>
                {/*<LoginButtons>

                                </LoginButtons>*/}
              </TextWrapper>
            </Column1>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
      {isLoading && <Overlay />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InfoSection));
