import {
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
} from "@material-ui/core";
import Input from "../../components/Input";

import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import "./AddEditBlog.css";
import {
  DashHeading,
  DashboardContainer,
  DashboardHeading,
  DashboardWrapper,
  MenuAndBack,
} from "./BlogElements";
// import { extractDate } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../../components/Overlay";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../store/actions";

import { programDataValidator } from "../../utils/validators";

import { DeleteOutline, Edit } from "@material-ui/icons";
import { get } from "lodash";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { toast } from "react-toastify";
import Nodata from "../../components/Nodata";
import AddModule from "./AddModule";
import { Modal } from "../../components/Modal";
import { SlClose } from "react-icons/sl";
import { Field, Form, Formik } from "formik";
import Coach from "./Coach";
const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
  tablseHeadingCell: {
    textAlign: "center",
    fontWeight: "600",
  },
  tablePadding: {
    padding: "5px",
    textAlign: "center",
    fontSize: "0.8rem",
    fontWeight: "800",
  },
  tableContainerHeight: {
    height: "70vh",
  },
  paperTableHeight: {
    height: "60vh",
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "@media (max-width: 780px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 968px)": {
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 480px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  tablePaginationStyle: {
    border: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  searchDesign: {
    borderRadius: "20px",
    boxShadow: "none",
    width: "21%",
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#1692B4" : "red",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "red",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "red" : "#1692B4",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const OfferManagement = ({ history, setUsers, userData }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const [totalData, setTotalData] = useState(90);
  const [searchedData, setSearchedData] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModuleModal, setOpenModuleModal] = useState(false);
  const [coachVal, setCoachVal] = useState([]);
  const [coach, setCoach] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [coursedata, setCoursedata] = useState({
    name: "",
    numberofWeeks: "",
    companyName: "",
    id: "",
    coach:[]
  });
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const { data } = await axios.post(`/addCourse`, values, {
        headers: {
          Authorization: token,
        },
      });
      setOpenModal(false);
      getCourseList();
      setIsLoading(false);
      toast.success("Course created Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCoursedata({
        name: "",
        numberofWeeks: "",
        companyName: "",
        id: "",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (values) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const { data } = await axios.put(
        `/editCoursedetails/${values.id}`,
        values,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setOpenModal(false);
      getCourseList();
      setIsLoading(false);
      toast.success("Course edited Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCoursedata({
        name: "",
        numberofWeeks: "",
        companyName: "",
        id: "",
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCourseList();
  }, []);
  const [moduledata, setModuledata]=useState([])
  // For Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const recordsAfterPagingAndSorting = () => {
    const result = Object.values(tableData); // Extract values from tableData object
    const sortedData = stableSort(result, getComparator(order, orderBy));

    // Calculate the starting index and ending index for the current page
    const startIndex = page * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, sortedData.length);

    // Return the subset of data for the current page
    return sortedData.slice(startIndex, endIndex);
  };

  const handleSortRequest = (cellId) => {
    console.log(cellId);
    console.log(orderBy);
    const isAsc = orderBy === cellId && order === "asc";
    // stableSort(tableData, getComparator(order, cellId))
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    // console.log(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  function getComparator(order, orderBy) {
    // return  (a, b) => descendingComparator(a, b, orderBy)
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return 1;
    }
    if (b[orderBy] > a[orderBy]) {
      return -1;
    }
    return 0;
  }
  const coachModalState=(state)=>{
    setOpenCModal(state)
    getCourseList()
  }
  const getCourseList = async (
    page = 1,
    rowsPerPage = 15,
    searchValue = "",
    startDate = "",
    endDate = "",
    dateFilter = ""
  ) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(
        `/getCourse?page=${page}&pageSize=${rowsPerPage}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setTableData(data.data);
      setSearchedData(data.data);
      setTotalData(data.data.length);
      setPage(page - 1);
      setRowsPerPage(rowsPerPage);
      setIsLoading(false);  
      setCoursedata({
        name: "",
        numberofWeeks: "",
        companyName: "",
        id: "",
      });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getCourseList();
  }, []);
  const deleteCourse = async (e) => {
    // Prompt the user to enter the admin password
    const adminPassword = prompt("Enter admin password:");

    // Check if the admin password is correct
    if (adminPassword === "123456") {
      if (window.confirm("Are you sure you want to delete this course?")) {
        try {
          const token = localStorage.getItem("token");

          const { data } = await axios.delete(`/deleteCourse/${e.categoryId}`, {
            headers: {
              Authorization: token,
            },
          });
          getCourseList();
          toast.error("Course deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // If the admin password is incorrect, show an error message
      alert("Incorrect admin password. Deletion canceled.");
    }
  };

  const onCoachChange = (val) => {
    setCoachVal(val);
  };
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const coachData = await axios.get(`/getAllCoach`, {
        headers: { Authorization: token },
      });
      setCoach(coachData.data.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [openCModal, setOpenCModal] = useState(false);
  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <DashboardHeading
              style={{ display: "flex", flexDirection: "column" }}
            >
              <MenuAndBack
                style={{
                  backgroundColor: "#1692B4",
                  width: "100%",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  class="fa-solid fa-list-check"
                  style={{ fontSize: "25px", margin: "8px" }}
                ></i>

                <DashHeading
                  style={{ color: "white", flex: "1", padding: "8px" }}
                >
                  Program Management
                </DashHeading>
              </MenuAndBack>
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ width: "100%" }}
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    background: "#1692B4",
                    border: "#1692B4",
                    width: "130px",
                    height: "40px",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  ADD NEW
                </button>
              </div>
            </DashboardHeading>

            <Paper
              className={classes.paperTableHeight}
              style={{
                overflow: "hidden",
                height: "60vh",
                marginBottom: "0.5rem",
              }}
            >
              <TableContainer className={classes.tableContainerHeight}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tablePadding}
                        style={{ fontWeight: "800" }}
                      >
                        S.&nbsp;No.
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Date
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Course Name
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Number of Weeks
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Company Name
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Actions
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Assign Coach
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((category, index) => (
                      <TableRow key={category._id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.textMiddle}
                        >
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {moment(get(category, "createdAt", "")).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {category.name !== null && category.name}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {category.numberofWeeks}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {category.companyName}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TableCell>
                              <div>
                                <Tooltip title={"Delete"} arrow>
                                  <Button
                                    aria-label="delete"
                                    onClick={() => {
                                      deleteCourse({
                                        categoryId: category._id,
                                      });
                                    }}
                                  >
                                    <DeleteOutline />
                                  </Button>
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div>
                                <Tooltip title={"Edit"} arrow>
                                  <Button
                                    aria-label="Edit"
                                    onClick={() => {
                                      setOpenModal(true);
                                      setCoursedata({
                                        name: category.name,
                                        numberofWeeks: category.numberofWeeks,
                                        companyName: category.companyName,
                                        id: category._id,
                                      });
                                    }}
                                    class="btn btn-primary"
                                    style={{
                                      background: "#1692B4",
                                      border: "#1692B4",
                                      height: "40px",
                                      width: "fit-content",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Edit Course
                                  </Button>
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div>
                                <Tooltip title={"Edit"} arrow>
                                  <Button
                                    aria-label="Edit"
                                    onClick={() => {
                                      setOpenModuleModal(true);
                                      setModuledata(category)
                                      setCoursedata((prev) => ({
                                        ...prev,
                                        numberofWeeks: category.numberofWeeks,
                                        id: category._id,
                                      }));
                                    }}
                                    class="btn btn-primary"
                                    style={{
                                      background: "#1692B4",
                                      border: "#1692B4",
                                      height: "40px",
                                      width: "fit-content",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Build Program
                                  </Button>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={"Assign Coach"} arrow>
                            <Button
                              aria-label="Edit"
                              onClick={() => {
                                setOpenCModal(true);
                                setCoursedata((prev) => ({
                                  ...prev,
                                  id: category?._id,
                                  coach:category?.coach
                                }));
                              }}
                              class="btn btn-primary"
                              style={{
                                background: "#1692B4",
                                border: "#1692B4",
                                height: "40px",
                                width: "fit-content",
                                cursor: "pointer",
                              }}
                            >
                              Assign Coach
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {tableData.length === 0 ? (
                <Nodata TextToDisplay="No Data Found." fontSize="24px" />
              ) : (
                false
              )}
              <TablePagination
                className={classes.tablePaginationStyle}
                rowsPerPageOptions={[15, 30, 100]}
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </DashboardWrapper>
        </DashboardContainer>
      </div>
      <Modal
        maxWidth="lg"
        width="700px"
        height="auto"
        RoundedCorners={true}
        isOpen={openModal}
        onClose={(event, reason) => {
          if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
          } else {
            setOpenModal(false);
            setCoursedata({
              name: "",
              numberofWeeks: "",
              companyName: "",
              id: "",
            });
          }
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div>
            <div
              className="my-3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'DM Sans', sans-serif",
              }}
            >
              {coursedata.id ? "Edit Course Data" : "Add New Course"}
            </div>
            <div className="">
              <SlClose
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                  setCoursedata({
                    name: "",
                    numberofWeeks: "",
                    companyName: "",
                    id: "",
                  });
                }}
              />
            </div>
          </div>
        }
        content={
          <>
            <Formik
              enableReinitialize
              validate={programDataValidator}
              validateOnChange
              initialValues={coursedata}
              onSubmit={(values) => {
                if (values.id) {
                  handleEdit(values);
                } else {
                  handleSubmit(values);
                }
              }}
            >
              {(formikBag) => (
                <Form>
                  <div className="signup-cont row">
                    <div className="col-md-12">
                      <label className={classes.offerLabel}>
                        Program title
                      </label>
                      <Field name="Enter Program title">
                        {({ field }) => (
                          <div className="pb-2 mt-1">
                            <Input
                              {...field}
                              type="text"
                              variant="outlined"
                              value={formikBag.values.name}
                              onChange={(e) => {
                                formikBag.setFieldValue("name", e.target.value);
                              }}
                              className="form-control"
                              placeholder="Program title"
                              error={
                                formikBag.touched.name && formikBag.errors.name
                                  ? formikBag.errors.name
                                  : null
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-md-12">
                      <label className={classes.offerLabel}>No. of weeks</label>
                      <Field name="Enter No. of weeks">
                        {({ field }) => (
                          <div className="pb-2 mt-1">
                            <Input
                              {...field}
                              type="number"
                              variant="outlined"
                              value={formikBag.values.numberofWeeks}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "numberofWeeks",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.numberofWeeks &&
                                formikBag.errors.numberofWeeks
                                  ? formikBag.errors.numberofWeeks
                                  : null
                              }
                              className="form-control"
                              placeholder="No. of weeks"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-md-12">
                      <label className={classes.offerLabel}>
                        Name of Company
                      </label>
                      <Field name="Enter Name of Company">
                        {({ field }) => (
                          <div className="pb-2 mt-1">
                            <Input
                              {...field}
                              type="text"
                              variant="outlined"
                              value={formikBag.values.companyName}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "companyName",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.companyName &&
                                formikBag.errors.companyName
                                  ? formikBag.errors.companyName
                                  : null
                              }
                              className="form-control"
                              placeholder="Name of Company"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style={{
                          background: "#1692B4",
                          border: "#1692B4",
                          height: "40px",
                          margin: "auto",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        }
      />
      <AddModule
        openModuleModal={openModuleModal}
        setOpenModuleModal={setOpenModuleModal}
        coursedata={coursedata}
        id={coursedata.id}
        moduledata={moduledata}
      />
      <Modal
        maxWidth="lg"
        width="400px"
        height="auto"
        RoundedCorners={true}
        isOpen={openCModal}
        onClose={(event, reason) => {
          if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
          } else {
            setOpenCModal(false);
          }
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div>
            <div
              className="my-3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'DM Sans', sans-serif",
              }}
            >
              {"Assign Coach"}
            </div>
            <div className="">
              <SlClose
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenCModal(false);
                }}
              />
            </div>
          </div>
        }
        content={
          <>
            <Coach coach={coach} onCoachChange={onCoachChange} id={coursedata.id} coachlist={coursedata.coach} coachModalState={coachModalState}/>
          </>
        }
      />
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferManagement));
