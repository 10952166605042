import React, { useEffect, useState } from "react";
import Section from "./Section";
import Videos from "./Videos";
import Documents from "./Documents";
import axios from "../../axios";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Input from "../../components/Input";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
}));

const CourseModule = (props) => {
  const classes = useStyles();
  const [modules, setModules] = useState([]);
  useEffect(() => {
    const mapModulesProgram = (modulesData) => {
      return modulesData.map((module) => ({
        sectionVal:
          module.courseSection.map((section) => ({
            label: section.heading,
            value: section._id,
          })) || [],
        videoVal:
          module.courseVideo.map((video) => ({
            label: video.title,
            value: video._id,
          })) || [],
        docVal:
          module.courseDoc.map((doc) => ({
            label: doc.title,
            value: doc._id,
          })) || [],
        participants: module?.participants,
        timeReq: module?.timeReq,
      }));
    };
    let weeksData = [];
    if (props?.title === "preProgram") {
      weeksData = props?.data?.preProgram || [];
      setModules(mapModulesProgram(weeksData));
    } else if (props?.title === "programOverview") {
      weeksData = props?.data?.programOverview || [];
      setModules(mapModulesProgram(weeksData));
    } else {
      weeksData = props?.data?.weeks || [];
      weeksData.map((week, index) => {
        if (props.title == index + 1) {
          setModules(mapModulesProgram(week.modules));
        }
      });
    }
  }, []);

  const addModule = () => {
    setModules([
      ...modules,
      {
        sectionVal: [],
        videoVal: [],
        docVal: [],
        participants: "",
        timeReq: "",
      },
    ]);
  };

  const removeModule = (index) => {
    setModules(modules.filter((_, i) => i !== index));
  };

  const handleModuleChange = (index, type, value) => {
    const updatedModules = [...modules];
    updatedModules[index][type] = value;
    console.log(updatedModules, "iiiiiiiiiiiiiiiiiiiiiiiii");
    setModules(updatedModules);
  };

  const handleSubmit = async (programType) => {
    const token = localStorage.getItem("token");
    try {
      if (modules.length === 0) {
        toast.error("Please assign atleast one module");
        return;
      }
      if (modules.some((module) => module?.sectionVal?.length === 0)) {
        toast.error("Please assign at least one module");
        return;
      }
      let payload;
      let weekpayload;
      if (programType === "programOverview" || programType === "preProgram") {
        payload =
          modules &&
          modules.map((module) => ({
            courseVideo: [
              ...(Array.isArray(module?.videoVal)
                ? module.videoVal.map((val) => val.value)
                : []),
              module?.videoVal?.value || [],
            ].flat(),
            courseSection: [
              ...(Array.isArray(module?.sectionVal)
                ? module.sectionVal.map((val) => val.value)
                : []),
              module?.sectionVal?.value || [],
            ].flat(),
            courseDoc: [
              ...(Array.isArray(module?.docVal)
                ? module.docVal.map((val) => val.value)
                : []),
              module?.docVal?.value || [],
            ].flat(),
          }));
      } else {
        weekpayload = {
          weekNumber: programType,
          modules:
            modules &&
            modules.map((module) => ({
              courseVideo: [
                ...(Array.isArray(module?.videoVal)
                  ? module.videoVal.map((val) => val.value)
                  : []),
                module?.videoVal?.value || [],
              ].flat(),
              courseSection: [
                ...(Array.isArray(module?.sectionVal)
                  ? module.sectionVal.map((val) => val.value)
                  : []),
                module?.sectionVal?.value || [],
              ].flat(),
              courseDoc: [
                ...(Array.isArray(module?.docVal)
                  ? module.docVal.map((val) => val.value)
                  : []),
                module?.docVal?.value || [],
              ].flat(),
              ...{ participants: module.participants },
              ...{ timeReq: module.timeReq },
            })),
        };
      }
      console.log(weekpayload, "weekkkkkkkkkkkkkkkkkk");

      let res;
      if (programType === "programOverview") {
        res = await axios.put(`/save-program-overview/${props.id}`, payload, {
          headers: {
            Authorization: token,
          },
        });
      } else if (programType === "preProgram") {
        res = await axios.put(`/save-preProgram/${props.id}`, payload, {
          headers: {
            Authorization: token,
          },
        });
      }
      else {
        res = await axios.put(`/save-weeks/${props.id}`, weekpayload, {
          headers: {
            Authorization: token,
          },
        });
      }
      toast.success(res.data.message);
      window.location.reload();
    } catch (error) {
      toast.error("Error updating course");
      console.error(error);
    }
  };

  return (
    <div>
      {modules.map((module, index) => (
        <div key={index} style={{ marginBottom: "15px" }}>
          <div className="col-md-12 p-0">
            <Section
              section={props.section}
              existsdata={module.sectionVal[0]}
              onSectionChange={(val) =>
                handleModuleChange(index, "sectionVal", val)
              }
            />
          </div>
          {props?.title !== "preProgram" &&
            props?.title !== "programOverview" && (
              <>
                <div className="col-md-12 p-0">
                  <label className={classes.offerLabel}>
                    No. of Participants
                  </label>
                  <Field name={`participants-${index}`}>
                    {({ field }) => (
                      <div className="pb-2 mt-1">
                        <Input
                          {...field}
                          type="number"
                          variant="outlined"
                          value={module.participants}
                          onChange={(e) =>
                            handleModuleChange(
                              index,
                              "participants",
                              e.target.value
                            )
                          }
                          className="form-control"
                          placeholder=" No. of Participants (optional)"
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-md-12 p-0">
                  <label className={classes.offerLabel}>Time required</label>
                  {console.log(module)}
                  <Field name={`timeReq-${index}`}>
                    {({ field }) => (
                      <div className="pb-2 mt-1">
                        <Input
                          {...field}
                          type="text"
                          variant="outlined"
                          value={module.timeReq} 
                          onChange={(e) =>
                            handleModuleChange(index, "timeReq", e.target.value)
                          }
                          className="form-control"
                          placeholder="Time required (optional)"
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </>
            )}
          <div className="col-md-12 p-0">
            <Videos
              video={props.video}
              existsdata={module.videoVal[0]}
              onVideoChange={(val) =>
                handleModuleChange(index, "videoVal", val)
              }
            />
          </div>
          <div className="col-md-12 p-0">
            <Documents
              doc={props.doc}
              existsdata={module.docVal[0]}
              onDocChange={(val) => handleModuleChange(index, "docVal", val)}
            />
          </div>

          <button
            className="btn btn-primary"
            type="button"
            style={{
              background: "#1692B4",
              border: "#1692B4",
              height: "40px",
              width: "150px",
              margin: "auto",
              cursor: "pointer",
            }}
            onClick={() => removeModule(index)}
          >
            Remove Module
          </button>
        </div>
      ))}

      <div className="row mt-3">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handleSubmit(props.title)}
            className="btn btn-primary"
            style={{
              background: "#1692B4",
              border: "#1692B4",
              height: "40px",
              margin: "auto",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </div>
      </div>

      <button
        onClick={addModule}
        className="btn btn-primary"
        type="button"
        style={{
          background: "#1692B4",
          border: "#1692B4",
          height: "40px",
          width: "fit-content",
          cursor: "pointer",
        }}
      >
        Add Course Module
      </button>
    </div>
  );
};

export default CourseModule;
