import React, { useState, useEffect } from "react";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";

const Section = ({
  section,
  onSectionChange,
  selectedSections,
  existsdata,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    if (existsdata) {
      const formattedSections ={
          label: existsdata.heading || existsdata.label,
          value: existsdata._id || existsdata.value,
        }
      setSelectedOptions(formattedSections);
    }
  }, [existsdata]);

  const handleSelectChange = (options) => {
    setSelectedOptions(options);
    onSectionChange(options);
  };

  return (
    <div className="col-md-12 mb-4 p-0">
      <label>Program Sections</label>
      <Select
        options={
          section &&
          section.map((item) => ({
            label: item.heading,
            value: item._id,
          }))
        }
        value={selectedOptions}
        onChange={handleSelectChange} 
        classNamePrefix="react-select"
        placeholder="Select Program Section"
      />
      {!selectedOptions.length === 0 && (
        <Typography color="error">At least one section is required</Typography>
      )}
    </div>
  );
};

export default Section;
