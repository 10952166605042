import React, { useState, useEffect } from "react";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import axios from "../../axios";
import { toast } from "react-toastify";

const Coach = ({ coach, onCoachChange, id, coachlist, coachModalState }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    if (coachlist) {
      const preselected = coachlist.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setSelectedOptions(preselected);
    } else {
      setSelectedOptions(coach);
    }
  }, [coachlist, coach]);

  const handleSelectChange = (options) => {
    setSelectedOptions(options);
    onCoachChange(options);
  };
  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    const ids = selectedOptions
      .filter((i) => i.value !== undefined && i.value !== null) // Filter out invalid values
      .map((i) => i.value);
    try {
      const res = await axios.put(
        `/saveCoach/${id}`,
        {
          coach: ids,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Coach assigned successfully");
      coachModalState(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="row">
      <div className="col-md-12 mb-4">
        <label>Assign Coach</label>
        <Select
          options={
            coach &&
            coach.map((item) => ({
              label: item.name,
              value: item._id,
            }))
          }
          value={selectedOptions}
          onChange={handleSelectChange}
          isMulti
          classNamePrefix="react-select"
          placeholder="Select Coach"
        />
      </div>
      <div className="row mt-3">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={handleSubmit}
            className="btn btn-primary"
            style={{
              background: "#1692B4",
              border: "#1692B4",
              height: "40px",
              margin: "auto",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Coach;
