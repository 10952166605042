import React, { useState, useEffect } from "react";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";

const Documents = ({ doc, onDocChange, existsdata }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (existsdata) {
      const formattedDocs = {
        label: existsdata.title || existsdata.label,
        value: existsdata._id || existsdata.value,
      }
      setSelectedOptions(formattedDocs);
    }
  }, [existsdata]);

  const handleSelectChange = (options) => {
    setSelectedOptions(options);
    onDocChange(options); // Pass selected options to parent
  };

  return (
    <div className="col-md-12 mb-4 p-0">
      <label>Program Documents</label>
      <Select
        options={
          doc &&
          doc.map((item) => ({
            label: item.title,
            value: item._id,
          }))
        }
        value={selectedOptions} // Controlled by local state
        onChange={handleSelectChange} // Handle changes and pass to parent
        classNamePrefix="react-select"
        placeholder="Select Program Document"
      />
    </div>
  );
};

export default Documents;
