import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SlClose } from "react-icons/sl";
import axios from "../../axios";
import Input from "../../components/Input";
import { Modal } from "../../components/Modal";
import Coach from "./Coach";
import CourseModule from "./CourseModule";

const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
}));
function AddModule(props) {
  const classes = useStyles();
  const [doc, setDoc] = useState();
  const [video, setVideo] = useState();
  const [section, setSection] = useState();

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const [docData, videoData, sectionData] = await Promise.all([
        axios.get(`/getAllDocument`, { headers: { Authorization: token } }),
        axios.get(`/getAllVideo`, { headers: { Authorization: token } }),
        axios.get(`/getSection`, { headers: { Authorization: token } }),
      ]);
      setDoc(docData.data.data);
      setVideo(videoData.data.data);
      setSection(sectionData.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal
      maxWidth="lg"
      width="700px"
      height="auto"
      RoundedCorners={true}
      isOpen={props.openModuleModal}
      onClose={(event, reason) => {
        if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
        } else {
          props.setOpenModuleModal(false);
        }
      }}
      backgroundModal={false}
      backgroundModalContent={false}
      title={
        <div>
          <div
            className="my-3"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "22px",
              fontFamily: "'DM Sans', sans-serif",
            }}
          >
            {"Build Program"}
          </div>
          <div className="">
            <SlClose
              style={{
                position: "absolute",
                top: 16,
                right: 16,
                cursor: "pointer",
              }}
              onClick={() => {
                props.setOpenModuleModal(false);
              }}
            />
          </div>
        </div>
      }
      content={
        <>
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values) => {
              //   handleNewCourse(values);
            }}
          >
            {(formikBag) => (
              <Form>
                <div className="signup-cont row">
                  <div className="row">
                    <div className="col-md-12">
                      <Accordion
                        style={{ margin: "15px 0px" }}
                        classes={{
                          root: classes.MuiAccordionroot,
                        }}
                      >
                        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                          Program Overview
                        </AccordionSummary>
                        <AccordionDetails className="row">
                          <CourseModule
                            section={section}
                            video={video}
                            doc={doc}
                            id={props.id}
                            title={"programOverview"}
                            data={props?.moduledata}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="col-md-12">
                      <Accordion
                        style={{ margin: "15px 0px" }}
                        classes={{
                          root: classes.MuiAccordionroot,
                        }}
                      >
                        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                          Pre Program Preparation
                        </AccordionSummary>
                        <AccordionDetails className="row">
                          <CourseModule
                            section={section}
                            video={video}
                            doc={doc}
                            id={props.id}
                            title={"preProgram"}
                            data={props?.moduledata}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {props?.coursedata?.numberofWeeks &&
                      [...Array(props?.coursedata?.numberofWeeks)].map(
                        (_, index) => {
                          return (
                            <div className="col-md-12">
                              <Accordion
                                style={{ margin: "15px 0px" }}
                                classes={{
                                  root: classes.MuiAccordionroot,
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ArrowDownwardIcon />}
                                >
                                  Week {index + 1}
                                </AccordionSummary>
                                <AccordionDetails className="row">
                                  <CourseModule
                                    section={section}
                                    video={video}
                                    doc={doc}
                                    id={props.id}
                                    title={`${index + 1}`}
                                    numberofWeeks={
                                      props?.coursedata?.numberofWeeks
                                    }
                                    data={props?.moduledata}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
}

export default AddModule;
