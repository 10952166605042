import {
  Button,
  Fade,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "./AddEditBlog.css";

import {
  DashHeading,
  DashboardContainer,
  DashboardHeading,
  DashboardWrapper,
  HeadingButton,
  MenuAndBack,
} from "./BlogElements";
// import { extractDate } from "../../utils/functions";
import axios from "../../axios";
import Overlay from "../../components/Overlay";

import { DeleteOutline, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../store/actions";
// import { uploadImage } from "../../utils/functions";
import { get } from "lodash";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { SlClose } from "react-icons/sl";
import { toast } from "react-toastify";
import Input from "../../components/Input";
import { Modal } from "../../components/Modal";
import Nodata from "../../components/Nodata";
import { DocDataValidator } from "../../utils/validators";
import { FaFilePdf } from "react-icons/fa";
import { handleImageUpload } from "../../utils/functions";
const useStyles = makeStyles((theme) => ({
  textMiddle: {
    verticalAlign: "middle !important",
    textAlign: "center",
  },
  tablseHeadingCell: {
    textAlign: "center",
    fontWeight: "600",
  },
  tablePadding: {
    padding: "5px",
    textAlign: "center",
    fontSize: "0.8rem",
    fontWeight: "800",
  },
  tableContainerHeight: {
    height: "70vh",
  },
  paperTableHeight: {
    height: "60vh",
    width: "95%",
    marginLeft: "2rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "@media (max-width: 780px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 968px)": {
    tableContainerHeight: {
      maxHeight: "64vh",
    },
  },
  "@media (max-width: 480px)": {
    paperTableHeight: {
      marginLeft: "0.75rem",
    },
  },
  tablePaginationStyle: {
    border: "1px solid #0000001a",
    borderRadius: "0rem 0rem 0.4rem 0.4rem",
    overflowY: "hidden",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  searchDesign: {
    borderRadius: "20px",
    boxShadow: "none",
    width: "21%",
  },
}));

const OfferManagement = ({ history, setUsers, userData }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(90);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };
  const [documentData, setDocumentData] = useState({
    title: "",
    document: "",
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    getAllDoc();
  }, []);

  // For Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [openVModal, setOpenVModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const recordsAfterPagingAndSorting = () => {
    const result = [];
    for (let i = 0; i <= tableData.length - 1; i++) {
      result.push(tableData[i]);
    }
    const sortedData = stableSort(result, getComparator(order, orderBy));

    // Calculate the starting index and ending index for the current page
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Return the subset of data for the current page
    return sortedData.slice(startIndex, endIndex);
  };

  const handleSortRequest = (cellId) => {
    console.log(cellId);
    console.log(orderBy);
    const isAsc = orderBy === cellId && order === "asc";
    // stableSort(tableData, getComparator(order, cellId))
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    // console.log(stabilizedThis.map((el) => el[0]));
    return stabilizedThis.map((el) => el[0]);
  };

  function getComparator(order, orderBy) {
    // return  (a, b) => descendingComparator(a, b, orderBy)
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return 1;
    }
    if (b[orderBy] > a[orderBy]) {
      return -1;
    }
    return 0;
  }

  const dateOfJoining = (e) => {
    var date = new Date(e).toLocaleDateString();
    return date;
  };
  const dateOfExpiry = (e) => {
    var date = new Date(e).toLocaleDateString();
    if (e) {
      return date;
    } else {
      return "N/A";
    }
  };
  const getAllDoc = async (
    page = 1,
    rowsPerPage = 15,
    searchValue = "",
    startDate = "",
    endDate = "",
    dateFilter = ""
  ) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(
        `/getAllDocument/?page=${page}&pageSize=${rowsPerPage}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setTableData(data.data);
      console.log(data.data);
      setTotalData(data.data.length);
      setPage(page - 1);
      setRowsPerPage(rowsPerPage);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("document", values.document);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.post(`/addDocument`, formData, {
        headers: {
          Authorization: token,
        },
      });
      setOpenModal(false);
      toast.success("Document Uploaded Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      setDocumentData({
        title: "",
        document: "",
      });
      getAllDoc();
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("document", values.document);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.put(`/editDocument/${values.id}`, formData, {
        headers: {
          Authorization: token,
        },
      });
      setOpenModal(false);
      toast.success("Document edited Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      setDocumentData({
        title: "",
        document: "",
      });
      getAllDoc();
    } catch (error) {
      console.log(error);
    }
  };
  const deleteDoc = async (e) => {
    // Prompt the user to enter the admin password
    const adminPassword = prompt("Enter admin password:");

    // Check if the admin password is correct
    if (adminPassword === "123456") {
      // Replace "your_admin_password" with the actual admin password
      if (window.confirm("Are you sure you want to delete this document?")) {
        try {
          const token = localStorage.getItem("token");

          const { data } = await axios.delete(
            `/deleteDocument/${e.categoryId}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          getAllDoc();
          toast.error("Document deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      // If the admin password is incorrect, show an error message
      alert("Incorrect admin password. Deletion canceled.");
    }
  };

  return (
    <>
      <div>
        <DashboardContainer>
          <DashboardWrapper>
            <DashboardHeading
              style={{ display: "flex", flexDirection: "column" }}
            >
              <MenuAndBack
                style={{
                  backgroundColor: "#1692B4",
                  width: "100%",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  class="fa-solid fa-file"
                  style={{ fontSize: "25px", margin: "8px" }}
                ></i>

                <DashHeading
                  style={{ color: "white", flex: "1", padding: "8px" }}
                >
                  Program Documents
                </DashHeading>
              </MenuAndBack>
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ width: "100%" }}
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{
                    background: "#1692B4",
                    border: "#1692B4",

                    height: "50px",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setOpenModal(true);
                    setDocumentData({
                      title: "",
                      document: "",
                    });
                  }}
                >
                  ADD DOCUMENT
                </button>
              </div>
            </DashboardHeading>

            <Paper
              className={classes.paperTableHeight}
              style={{
                overflow: "hidden",
                height: "60vh",
                marginBottom: "0.5rem",
              }}
            >
              <TableContainer className={classes.tableContainerHeight}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tablePadding}
                        style={{ fontWeight: "800" }}
                      >
                        S.&nbsp;No.
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Date
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Title
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Document
                      </TableCell>
                      <TableCell className={classes.tablePadding}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {recordsAfterPagingAndSorting().map((category, index) => (
                      <TableRow key={category.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.textMiddle}
                        >
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          {moment(get(category, "createdAt", "")).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>

                        <TableCell className={classes.textMiddle}>
                          <div>{get(category, "title", "")}</div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>
                            <div>
                              <a
                                href={category?.document}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <IconButton>
                                  <FaFilePdf
                                    fontSize="large"
                                    style={{ color: "black" }}
                                  />
                                </IconButton>
                              </a>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.textMiddle}>
                          <div>
                            <Tooltip title={"Delete"} arrow>
                              <Button
                                aria-label="delete"
                                // className={classes.Marginbutton}
                                onClick={() => {
                                  deleteDoc({ categoryId: category._id });
                                }}
                              >
                                <DeleteOutline />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Edit" arrow>
                              <Button
                                // variant="outlined"
                                aria-label="add"
                                className={classes.iconMargin}
                                onClick={() => {
                                  setDocumentData({
                                    title: category.title,
                                    document: category.document,
                                    id: category._id,
                                  });
                                  setOpenModal(true);
                                }}
                              >
                                <Edit color="primary" />
                              </Button>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {tableData.length === 0 ? (
                  <Nodata TextToDisplay="No Data Found." fontSize="24px" />
                ) : (
                  false
                )}
              </TableContainer>

              <TablePagination
                className={classes.tablePaginationStyle}
                rowsPerPageOptions={[15, 30, 100]}
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </DashboardWrapper>
        </DashboardContainer>
      </div>

      <Modal
        maxWidth="lg"
        width="700px"
        height="auto"
        RoundedCorners={true}
        isOpen={openModal}
        // RoundedCorners={true}
        onClose={(event, reason) => {
          if (reason && (reason === "backdropClick" || "escapeKeyDown")) {
          } else {
            setOpenModal(false);
            setDocumentData({
              title: "",
              document: "",
            });
          }
        }}
        backgroundModal={false}
        backgroundModalContent={false}
        title={
          <div>
            <div
              className="my-3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'DM Sans', sans-serif",
              }}
            >
              {documentData.id ? "Edit Document" : "Add New Document"}
            </div>
            <div className="">
              <SlClose
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenModal(false);
                  setDocumentData({
                    title: "",
                    document: "",
                  });
                }}
              />
            </div>
          </div>
        }
        content={
          <>
            <Formik
              key={documentData}
              enableReinitialize
              initialValues={documentData}
              validate={DocDataValidator}
              validateOnChange
              onSubmit={(values) => {
                if (values.id) {
                  handleEdit(values);
                } else {
                  handleSubmit(values);
                }
              }}
            >
              {(formikBag) => {
                return (
                  <Form>
                    {console.log(formikBag.values)}
                    <div className="signup-cont">
                      <div className="row">
                        <div className="col-md-12">
                          <label className={classes.offerLabel}>
                            Document Title
                          </label>
                          <Field name="Enter Title">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <Input
                                  {...field}
                                  type="text"
                                  variant="outlined"
                                  autocomplete="off"
                                  value={formikBag.values.title}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "title",
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formikBag.touched.title &&
                                    formikBag.errors.title
                                      ? formikBag.errors.title
                                      : null
                                  }
                                  className="form-control"
                                  placeholder="Document Title"
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <label className={classes.offerLabel}>Document</label>
                          <Field name="Enter Email">
                            {({ field }) => (
                              <div className="pb-2 mt-1">
                                <input
                                  {...field}
                                  type="file"
                                  accept="*/*"
                                  onChange={async (e) => {
                                    setIsLoading(true);
                                    const selectedFile =
                                      e.currentTarget.files[0];
                                    const url = await handleImageUpload(
                                      selectedFile
                                    );
                                    formikBag.setFieldValue("document", url);
                                    setSelectedFileName(selectedFile.name);
                                    setIsLoading(false)
                                  }}
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  className="form-control"
                                  placeholder="Upload File"
                                />

                                <Button
                                  variant="contained"
                                  class="btn btn-primary"
                                  style={{
                                    background: "#1692B4",
                                    border: "#1692B4",
                                    height: "40px",
                                    margin: "auto",
                                  }}
                                  startIcon={<CloudUploadIcon />}
                                  onClick={() => {
                                    if (fileInputRef.current) {
                                      fileInputRef.current.click();
                                    }
                                  }}
                                >
                                  Choose File
                                </Button>
                                <div style={{ color: "red" }}>
                                  {formikBag.touched.document &&
                                  formikBag.errors.document
                                    ? formikBag.errors.document
                                    : null}
                                </div>
                                <div>
                                  {selectedFileName && (
                                    <>
                                      <span>
                                        Selected file: {selectedFileName}
                                      </span>

                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        onClick={() => {
                                          // Clear selected file and input field
                                          setSelectedFileName("");
                                          formikBag.setFieldValue(
                                            "document",
                                            null
                                          );
                                          if (fileInputRef.current) {
                                            fileInputRef.current.value = "";
                                          }
                                        }}
                                        className="ml-2"
                                      >
                                        Cancel
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div
                        className="col-md-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <HeadingButton
                          type="submit"
                          class="btn btn-primary"
                          style={{
                            background: "#1692B4",
                            border: "#1692B4",
                            height: "40px",
                            margin: "auto",
                          }}
                        >
                          Save
                        </HeadingButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        }
      />
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferManagement));
