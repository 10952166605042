import React from "react";

export const SidebarData = [
  {
    title: "Coach Management",
    path: "/coach-management",
    icon: <i class="fa-solid fa-users"></i>,
  },
  {
    title: "Program Management",
    path: "/program-management",
    icon: <i class="fa-solid fa-list-check"></i>,
  },
  {
    title: "Program Documents",
    path: "/program-document",
    icon: <i class="fa-solid fa-file"></i>,
  },
  {
    title: "Program Sections",
    path: "/program-section",
    icon: <i class="fa-solid fa-certificate"></i>,
  },
  {
    title: "Program Videos",
    path: "/program-videos",
    icon: <i class="fa-solid fa-video"></i>,
  },
];
