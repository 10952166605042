import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LoginSection from "./components/LoginSection";
import Navbar from "./components/Navbar";
import Sidebar from "./components/SidebarHaydii/Sidebar";
import "./index.css";
import ProgramDoc from "./pages/Program Document/ProgramDocument";
import ProgramManagement from "./pages/Program Management/ProgramManagement";
import ProgramSection from "./pages/Program Section/ProgramSection";
import ProgramVideos from "./pages/ProgramVideos/ProgramVideos";
import UserManagement from "./pages/User Management/CoachManagement";
import * as actionTypes from "./store/actions";

const PublicRoute = (props) => {
  const { defaultState, setDefaultState } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Switch>
      <Route path="/" exact>
        <LoginSection {...defaultState} />
      </Route>
      <Route path="/">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

const PrivateRoute = (props) => {
  const { defaultState, setDefaultState, userData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Switch>
      <Route path="/coach-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <UserManagement />
      </Route>
      <Route path="/program-management" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ProgramManagement />
      </Route>
      <Route path="/program-document" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ProgramDoc />
      </Route>
      <Route path="/program-videos" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ProgramVideos />
      </Route>
      <Route path="/program-section" exact>
        <Navbar toggle={toggle} />
        <Sidebar />
        <ProgramSection />
      </Route>
      <Route exact path="/">
        <LoginSection {...defaultState} />
      </Route>
    </Switch>
  );
};

function App(props) {
  const { defaultState, setDefaultState, userData } = props;
  const [userToken, setUserToken] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const token = localStorage.getItem("token");

  return (
    <div>
      {userToken === true ? <PrivateRoute /> : <PublicRoute />}
      <ToastContainer theme="colored" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locationData: state.locations,
    defaultState: state.defaultState,
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setLocations: (updatedValue) => {
      dispatch({
        type: actionTypes.GET_LOCATIONS,
        locationData: updatedValue,
      });
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
