import React, { useState, useEffect } from "react";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";

const Videos = ({ video, onVideoChange, existsdata }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Sync selected videos with parent component's state
  useEffect(() => {
    if (existsdata) {
      const formattedVideos = {
        label: existsdata.title || existsdata.label,
        value: existsdata._id || existsdata.value,
      };
      setSelectedOptions(formattedVideos);
    }
  }, [existsdata]);

  const handleSelectChange = (options) => {
    setSelectedOptions(options);
    onVideoChange(options); // Pass selected options to parent
  };

  return (
    <div className="col-md-12 mb-4 p-0">
      <label>Program Videos</label>
      <Select
        options={
          video &&
          video.map((item) => ({
            label: item.title,
            value: item._id,
          }))
        }
        value={selectedOptions} // Controlled by local state
        onChange={handleSelectChange} // Handle changes and pass to parent
        classNamePrefix="react-select"
        placeholder="Select Program Video"
      />
    </div>
  );
};

export default Videos;
